/* eslint-disable no-console,no-undef,camelcase */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  commaSeperator,
  convertDateInTimezone,
  getStatus,
  getTimeToShow,
  getLastSyncDetails,
  rankingDashboard,
  convertMilesToKm,
  convertMonthsWeeksText
} from "../../../utils/methods";
import moment from 'moment';
import { connect } from "react-redux";
import Image from '../../Image';
import isEmpty from 'lodash/isEmpty';
import Waiting from '../../Waiting';
import { joinOrLeaveChallenge, getInviteAmigosList } from "../../../redux/actions/challengeActions";
import { MyTaskContainerV2 } from '../MyTaskTab/styles';
import { EventsButton, ButtonContainer } from '../styles';
import { AboutContainer } from '../../EventDetails/styles';
import { PositionCard, LastSync, ParticipantCard } from '../OverViewScreen/styles';
import { Border } from '../../EventDetails/styles';
import { ImageUrl } from '../../../utils/constants';
import isNull from 'lodash/isNull';
const InviteAmigoPopUp = lazy(() => import("../InviteAmigoPopUp/inviteChallengePopup"));
import { withTranslation } from 'react-i18next';
import { GroupChallengeContainerV2, ProgressTextCard, ActivityContainerV2, ActivityBarNew, ActivityBarFillNew} from './styles';
import parse from 'react-html-parser';
// import LazyImage from '../../common/LazyImage/LazyImage';
class GroupRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
      isButtonDisabled: false
    }
  }
  componentDidMount() {
    const { challenge } = this.props;
    // getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);

  }
  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  render() {
    const { challenge, userId, syncTime, t, challengeAttendees } = this.props;
    const { time, isButtonDisabled } = this.state;
    if ((!(this.props.userChallengeDetails) || !userId)) {
      return <Waiting />
    }
    let propsChallengeCompetitors = [];
    if (challenge.challenge_type === 'new_group') {
      const { user_details: userDetails } = this.props.userChallengeDetails;
      propsChallengeCompetitors = userDetails;
    
    } else {
      propsChallengeCompetitors = this.props.userChallengeDetails['user_details'];
    }
    let challengeCompetitors = {};
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const joinOrLeave = (challenge.id !== 53) ? !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1) : (challenge.challenge_status === 'joined' ? 0 : 1);
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    else if (this.props.userChallengeDetails) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    const syncUpdate = getLastSyncDetails(syncTime);
    if (_.isUndefined(challengeCompetitors)) {
      return <Waiting />
    }
    
    const{ userChallengeDetails, userChallengeDetails:{team_details: teamdetails}} = this.props;
    if(!userChallengeDetails || Object.keys(userChallengeDetails).length === 0 || isEmpty(teamdetails) ){
      return <Waiting />
    }
    const TeamDetails = teamdetails;
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    const ChallengeType = challenge.event_type;
    const activityType = ChallengeType === 'Total Distance' ? 'distance' : ChallengeType === 'Total Steps' ? 'steps' : 'calories';
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <MyTaskContainerV2>
        {challenge.is_joined === 1 ?null:challenge.challenge_status !== 'over'&&
          <EventsButton backgroundColor={"#005C87"} color={"white"} onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"} boxShadow="0px 8px 24px 0px #005C8780">
            {t("Join Challenge")}
          </EventsButton>}
        {challenge.challenge_status === 'joined' && !isEmpty(challengeCompetitors) && challengeCompetitors && 
          <PositionCard color={'#005C87'}>
            <div>
              <img src={`${ImageUrl}/${challengeCompetitors['profile_image']}`} className="profile" />
            </div>
            <div>
              <div>
                <div className="attendies">{t("Overall Position")}
                </div>
                {isNull(challengeCompetitors['user_rank'])?(today < startDay)?<div>-</div>:<div>0</div>:
                  (today < startDay)?<div>-</div>:
                    <div>{challengeCompetitors['user_rank']}{rankingDashboard(challengeCompetitors['user_rank']-1)} {t("Rank")}</div>}
              </div>
              <Border />
              <div>
                <div>{t("Group Goal Achieved")}</div>
                {(today < startDay)?<div>-</div>:<div>{TeamDetails['percentage']===0?0:`${TeamDetails['percentage']}%`}</div>}
              </div>
            </div>
          </PositionCard>}

        {TeamDetails && <GroupChallengeContainerV2>
          <ProgressTextCard>
            <div>
              <div>{activityType === 'distance' ? DistanceUnit === "KILOMETER"? "Km":'Miles' : activityType === 'steps' ? 'Steps' : 'Calories'} {t("Progress")}</div>
              <div>{activityType === 'distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(TeamDetails['team_total'])) :commaSeperator(TeamDetails['team_total']) :  commaSeperator(TeamDetails['team_total'])  }</div>
            </div>
            <div>
              <div>{`${(100 - TeamDetails['percentage'])}%`}</div>
              <div>{t("To Hit Group Goal")}</div>
            </div>
          </ProgressTextCard>
          <ActivityContainerV2>
            <ActivityBarNew>
              <ActivityBarFillNew width={`${TeamDetails['percentage']}%`} fillColor={'1'} filled={TeamDetails['percentage'] < 10} padding="0" imageMargin={TeamDetails['percentage']<6?"27px":"auto"}>
                <img src={ImageUrl+"/ChallengeDetailsScreen/trophy.png"} alt="back-arrow" />
              </ActivityBarFillNew>
            </ActivityBarNew>
          </ActivityContainerV2>
          <div>
            <div className="totalGroupSteps">
              <div className="goalAchieve">{t("Team Goal To Achieve")}</div>
              <div className="goalPercent">{activityType === 'distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(TeamDetails['team_goal'])): commaSeperator(TeamDetails['team_goal']) :commaSeperator(TeamDetails['team_goal'])}<span className="steps"> {activityType === 'distance' ? DistanceUnit === "KILOMETER"?"Km":'Miles' : activityType === 'steps' ? 'Steps' : 'Cals'}</span></div>
            </div>
          </div>
        </GroupChallengeContainerV2>}  
        {challenge.challenge_status === 'joined' && !isEmpty(challengeCompetitors) && (challenge.event_type === 'Total Steps' ?
          <PositionCard color={'#76AB78'}>
            <div>
              <div className='img'>
                <img src={ImageUrl+"/ChallengeDetailsScreen/Steps.svg"} alt="back-arrow" />
              </div>
            </div>
            <div>
              <div>
                <div className="attendies">{t("My Total Steps")}
                </div>
                {challengeCompetitors['steps'] !== null ? (today < startDay)?<div>-</div>:<div>{commaSeperator(challengeCompetitors['steps'])}
                </div> :(today < startDay)?<div>-</div>: <div>0</div>}
              </div>
              <Border />
              <div>
                <div>{t("My Daily Average")}</div>
                {(today < startDay)?<div>-</div>:<div>{commaSeperator(challengeCompetitors['daily_average'])}</div>}
              </div>
            </div>
          </PositionCard> :
          challenge.event_type === 'Total Calories' ?
            <PositionCard color={'#FFBF73'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/Calories.svg"} alt="back-arrow" />
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Calories")}
                  </div>
                  {challengeCompetitors['calories'] !== null ? (today < startDay)?<div>-</div>: <div>{commaSeperator(challengeCompetitors['calories'])}
                  </div> : (today < startDay)?<div>-</div>:<div>0</div>}
                </div>
                <Border />
                <div>
                  <div>{t("My Daily Average")}</div>
                  {(today < startDay)?<div>-</div>:<div>{commaSeperator(challengeCompetitors['daily_average'])}</div>}
                </div>
              </div>
            </PositionCard> :
            <PositionCard color={'#85C0EA'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl + "/ChallengeDetailsScreen/Distance.svg"}/>
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Distance")}
                  </div>
                  {challengeCompetitors['distance'] !== null ? (today < startDay)?<div>-</div>: <div>{commaSeperator(challengeCompetitors['distance'])}
                  </div> : (today < startDay)?<div>-</div>: <div>0</div>}
                </div>
                <Border />
                <div>
                  <div>{t("My Daily Average")}</div>
                  {(today < startDay)?<div>-</div>:<div>{commaSeperator(challengeCompetitors['daily_average'])}</div>}
                </div>
              </div>
            </PositionCard>)}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over')&&<ParticipantCard >
          <div className='image'>
            <img src={ImageUrl + "/images/NewDashboardV2/EventsAttendies.png"}/>
          </div>
          <div className='text'>{this.props.t("Participants")}</div>
          <div className='count'>{challengeAttendees && challengeAttendees.length}</div>
        </ParticipantCard>}
        {challenge.challenge_status === 'joined' && syncUpdate.length>0 &&<LastSync>
          <div>{t("Last Sync")}</div>
          {!(today < startDay)?<div>{syncUpdate.length >= 2 ? ( localStorage.getItem('lang') != 'fr' ? `${syncUpdate[0]} ${syncUpdate[1]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}, ${convertMonthsWeeksText(syncUpdate[1], this.props.t)}`) : ( localStorage.getItem('lang') != 'fr' ? `${syncUpdate[0]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}`)}</div>:<div>-</div>}
        </LastSync>}
        {challenge.challenge_type == 'new_group' && challenge.challenge_status !== 'over' ?
          <ButtonContainer >
            <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#005C87"} colorValue="#005C87">
              <div>{t("About The Challenge")}</div>
              <div >{parse(challenge.body)}</div>
            </AboutContainer>{/*  */}
            {challenge.is_joined === 1 ?
              <EventsButton backgroundColor={"#005C870D"} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"} margin={"25px"}>
                {t("You Joined This Challenge")}
              </EventsButton>:null} 
          </ButtonContainer> : null}
        {challenge && challenge.challenge_status === 'over' &&
            <EventsButton backgroundColor={'#F4AAA94D'} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
              {t("Challenge Completed")}
            </EventsButton>}
        <Suspense fallback={<Waiting/>}><InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1}/></Suspense>
      </MyTaskContainerV2>
    )
  }
}
GroupRightSection.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  joinOrLeaveChallenge: PropTypes.func,
  syncTime: PropTypes.string,
  getInviteAmigosList: PropTypes.func,
  history: PropTypes.object,
  role: PropTypes.string,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  syncTime: state.challenges.syncTime,
  challengeAttendees: state.challenges.challengeAttendees
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupRightSection));
