import React, { Component } from 'react';
// import { ActivityContainerV2 } from './styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Waiting from '../../Waiting';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { commaSeperator, convertDateInTimezone, rankingDashboard, convertMilesToKm } from "../../../utils/methods";
import moment from 'moment';
import { ParticipantCount } from '../MyTaskTab/styles';
import { /*ActivityBarFillNew, ActivityBarNew, ProgressTextCard, GroupChallengeContainerV2, TopPositionCard, TopPositionCardText, TopPositionProfile,*/ LastPositionMain, TeamGoalContainerV2, LeaderboardCard, /*PositionCards*/ } from './styles';
import { ImageUrl } from '../../../utils/constants';
import { withTranslation } from 'react-i18next';

class GroupChallengeLeaderboard extends Component {
  render() {
    const { challenge, userChallengeDetails, userChallengeDetails: { team_details: teamdetails, participant_details }, t, challengeAttendees } = this.props;
    if (!userChallengeDetails || Object.keys(userChallengeDetails).length === 0 || isEmpty(teamdetails) || !participant_details) {
      return <Waiting />
    }
    // const TeamDetails = teamdetails;
    // const top3Contributors = top3;
    const topOtherContributors = participant_details;
    const ChallengeType = challenge.event_type;
    const activityType = ChallengeType === 'Total Distance' ? 'distance' : ChallengeType === 'Total Steps' ? 'steps' : 'calories';
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      challenge && (challenge.challenge_status !== 'over') && (today < startDay) ?
        <ParticipantCount >
          <div>{t("Total Participants")}</div>
          <div>{challengeAttendees ? challengeAttendees.length : 0}</div>
        </ParticipantCount > :(challenge.challenge_status == 'joined'||challenge.challenge_status == 'over')?
          <TeamGoalContainerV2>
            <div className='headingMain'>
              <div className='leader'>{this.props.t("Top Leaders")}</div>
              <div className='participantsCount'>{ topOtherContributors && (topOtherContributors.length)} {t("Participants")}</div>
            </div>
            {/* {today > startDay && <GroupChallengeContainerV2>
            <ProgressTextCard>
              <div>
                <div>{t("Progress")}</div>
                <div>{activityType === 'distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(TeamDetails['team_total'])) :commaSeperator(TeamDetails['team_total']) :  commaSeperator(TeamDetails['team_total'])  }<span>{activityType === 'distance' ? DistanceUnit === "KILOMETER"? "Km":'Miles' : activityType === 'steps' ? 'Steps' : 'Calories'}</span></div>
              </div>
              <div>
                <div>{`${(100 - TeamDetails['percentage'])}%`}</div>
                <div>{t("To hit group Goal")}</div>
              </div>
            </ProgressTextCard>
            <ActivityContainerV2>
              <ActivityBarNew>
                <ActivityBarFillNew width={`${TeamDetails['percentage']}%`} fillColor={'1'} filled={TeamDetails['percentage'] < 10} />
              </ActivityBarNew>
            </ActivityContainerV2>
            <div>
              <div className="totalGroupSteps">
                <div className="goalAchieve">{t("Team Goal To Achieve")}</div>
                <div className="goalPercent">{activityType === 'distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(TeamDetails['team_goal'])): commaSeperator(TeamDetails['team_goal']) :commaSeperator(TeamDetails['team_goal'])}<span className="steps"> {activityType === 'distance' ? DistanceUnit === "KILOMETER"?"Km":'Miles' : activityType === 'steps' ? 'Steps' : 'Calories'}</span></div>
              </div>
            </div>
          </GroupChallengeContainerV2>} */}
            {challenge && (today > startDay) &&
            <LastPositionMain style={{marginTop:'0px', paddingTop:"15px"}}>
              {/* {top3Contributors && top3Contributors.map((data, index) =>
                (<LeaderboardCard key={index} positionColor={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":'#005C87'} border={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":0}>
                  {index===0&&<div className="topPositionCard">
                    <img src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                  </div>}
                  <div className="first">
                    <div>
                      <img src={`${ImageUrl}/${data.profile_image}`} alt={data.profile_image} />
                    </div>
                    <div>
                      <div>{`${index+1}${rankingDashboard(index)}`}</div>
                      <div>{data.fullname}</div>
                    </div>
                  </div>
                  <div className="last">
                    <div>{isNull(data[`${activityType}`]) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"?`${commaSeperator(convertMilesToKm(data[`${activityType}`]))} `: `${commaSeperator(data[`${activityType}`])} `: `${commaSeperator(data[`${activityType}`])} ` } <div className="type">{` ${activityType === 'distance' ? DistanceUnit === "KILOMETER"?'Km':'Miles' : activityType}`}</div></div>
                    <div />
                  </div>
                </LeaderboardCard>))} */}
              {topOtherContributors && topOtherContributors.map((data, index) => (
                <LeaderboardCard style={{position:'relative'}} key={index} positionColor={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":'#005C87'} border={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":0}>
                  {index===0&&<div className="topPositionCard">
                    <img src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                  </div>}
                  <div className="first">
                    <div>
                      <img src={`${ImageUrl}/${data.profile_image}`} alt={""} />
                    </div>
                    <div>
                      <div>{`${index+1}${rankingDashboard(index)}`}</div>
                      <div>{data.fullname}</div>
                    </div>
                  </div>
                  <div className="last">
                    <div>{isNull(data[`${activityType}`]) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"?`${commaSeperator(convertMilesToKm(data[`${activityType}`]))} `: `${commaSeperator(data[`${activityType}`])} `: `${commaSeperator(data[`${activityType}`])} ` } <div className="type">{` ${activityType === 'distance' ? DistanceUnit === "KILOMETER"?'Km':'Miles' : activityType}`}</div></div>
                    <div />
                  </div>
                </LeaderboardCard>))}
            </LastPositionMain>}
          </TeamGoalContainerV2>:
          <ParticipantCount >
            <div>{t("Total Participants")}</div>
            <div>{challengeAttendees ? challengeAttendees.length : 0}</div>
          </ParticipantCount >
    );
  }
}
GroupChallengeLeaderboard.propTypes = {
  challenge: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userChallengeDetails: PropTypes.object,
  networkError: PropTypes.string,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  challengeAttendees: state.challenges.challengeAttendees,
});

export default connect(mapStateToProps)(withTranslation()(GroupChallengeLeaderboard));